import React from "react";
import { Route, Routes } from "react-router-dom";
import MainPage from "./Pages/MainPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Terms from "./Pages/Terms";

const App = () => {

    return (
        <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<Terms />} />
        </Routes>
    );
};

export default App;
