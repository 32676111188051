import React from 'react';
import appLogo from "../imgs/logo.webp";
import appStoreLogo from "../imgs/appstore-logo.webp"

const MainPage = () => {
    return (
        <div className="main-container">
            <div className="content-div">
                <img className="app-logo" src={appLogo} style={{width: "10vw"}} alt="App Logo"/>
                <div className="title-text">
                    Find the perfect
                </div>
                <div className="title-text">
                    Watch Face
                </div>
                <div className="description-div">
                    Explore our Watch Face Gallery & Widgets app for stylish and personalized Watch Faces. Enhance your Apple Watch and express your unique style.
                </div>
                <a href="https://apps.apple.com/us/app/watch-faces-by-watchcraft/id6477713199">
                    <img className="appstore-logo" style={{width: "15vw"}} src={appStoreLogo} alt="Appstore Logo" />
                </a>
                <div className="second-description-div">
                    One-Tap Customization for Your Apple Watch
                </div>
                <div className="links-div">
                    <a target="_blank" href="/terms-of-use">Terms of Use</a>
                    <a target="_blank" href="/privacy-policy">Privacy Policy</a>
                    <a href="mailto:contact@watch-faces.store">Contact Us</a>
                </div>
                <div className="footer">
                    <span>Custom Screens Widgets Icons Themes Wallpapers</span>
                    <span>Posters OÜ</span>
                    <span>Register Number: 16767726</span>
                    <span>Address: Tallinn, Vesivärava tn 50-201, 10152(Harju maakond, Kesklinna linnaosa)</span>
                    <span>Email: contact@watch-faces.store</span>
                </div>
            </div>
        </div>
    );
};

export default MainPage;